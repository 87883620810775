import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { newLineToParagraphs } from '../../utilities/utilities';
import SectionCTA from './SectionCTA';

import './section.css';

import data from '../../../content/data/index/index.yml';

function SectionFive() {
    const [ myData, setData ] = useState('');
    
    useEffect(() => setData(newLineToParagraphs(data.section5)), []);

    return (
        <section>
            <H2>
                <span>Month-to-month.</span><br />
                No contracts.<br />
                Cancel anytime.
            </H2>
            <P dangerouslySetInnerHTML={{ __html: myData }} />
            
            <P><SectionCTA color='#FF3366' /></P>
            <IMG src="/images/icons.png" />
        </section>
    );
}

const H2 = styled('h2')`
    color: #FFF;
    font-weight: bold;
    font-size: 102px;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    letter-spacing: -0.05em;

    span {
        color: #FF3366;
    }
`;

const P = styled('p')`
    width: 70%;
    color: #FFF;
    font-size: 32px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
`;  

const IMG = styled('img')``;

export default SectionFive;