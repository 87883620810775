import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import BenefitRotator from './BenefitRotator';

function Hero() {
    return (
        <BOX>
            <H1>Bring <span>life</span> back<br />
                to your dreams.
            </H1>
            <H3>Affordable, fast, revolutionary membership to<br /> 
                <span>{<BenefitRotator />}</span>.
            </H3>
            <BUTTON to='/pricing'>
                Sign up for $9 &nbsp;&nbsp;<i className='material-icons'>arrow_forward</i>
            </BUTTON>
        </BOX>
    );
}

const BOX = styled('div')`
    text-align: center;
    color: #FFF;
    letter-spacing: -0.05em;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

    // temp
    padding: 100px 0;

    @media (max-width: 735px) {
        padding: 20px 0;
        margin-top: 40px;
    }

    @media (max-width: 400px) {
        padding-top: 0;
    }
`;

const H1 = styled('h1')`
    font-weight: bold;
    font-size: 102px;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

    span {
        color: #00BBFF;
    }

    @media (max-width: 1035px) {
        font-size: 70px;
    }

    @media (max-width: 735px) {
        font-size: 40px;
    }

    @media (max-width: 400px) {
        font-size: calc(6px + 8vw);
    }
`;

const H3 = styled('h3')`
    color: #969696;
    font-size: 30px;
    margin-bottom: 65px;

    // BenefitRotator
    span {
        color: #FF69B4;
    }

    @media (max-width: 1035px) {
        font-size: 27px;
    }

    @media (max-width: 735px) {
        font-size: 20px;
    }

    @media (max-width: 460px) {
        font-size: calc(8px + 2vw);
    }

    @media (max-width: 350px) {
        font-size: calc(6px + 2vw);
    }
`;

const BUTTON = styled(Link)`
    color: #FFF;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
    background: #0099FF;
    padding: 15px 20px;
    border-radius: 100px;
    transition: background 0.3s ease-out;

    i {
        position: relative;
        font-size: 1.5rem;
        top: 6px;
    }

    &:hover {
        background: #0AF;
    }
`;

export default Hero;