import React from 'react';
import styled from '@emotion/styled';

import SectionCTA from './SectionCTA';

import './section.css';

function SectionSix() {
    return (
        <section>
            <H2>
                <span>Start getting</span>&nbsp;<br />
                        insights today.
            </H2>
            <P><SectionCTA color='#00BBFF' /></P>
        </section>
    );
}

const H2 = styled('h2')`
    color: #FFF;
    font-weight: bold;
    font-size: 102px;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    letter-spacing: -0.05em;

    span {
        color: #00BBFF;
    }
`;

const P = styled('p')`
    width: 70%;
    color: #FFF;
    font-size: 32px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
`;

export default SectionSix;