import React from "react"
// import { Link } from "gatsby"
import styled from '@emotion/styled';

// import Image from "../components/image"
import SEO from "../components/seo"

import Hero from '../components/Hero';
import SectionOne from '../components/Section/SectionOne';
import SectionTwo from '../components/Section/SectionTwo';
import SectionThree from '../components/Section/SectionThree';
import SectionFour from '../components/Section/SectionFour';
import SectionFive from '../components/Section/SectionFive';
import SectionSix from '../components/Section/SectionSix';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <CENTER>
      <Hero />
      <IMG src="https://source.unsplash.com/7KLa-xLbSXA/1050x700" alt='lens bringing ocean and mountains into focus' />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </CENTER>
    <FINALCTADIV>
      <CENTER>
        <SectionSix />
      </CENTER>
    </FINALCTADIV>
  </>
)

const FINALCTADIV = styled('div')`
  background: #111;
  padding-bottom: 4rem;
`;

const CENTER = styled('main')`
  width: 80%;
  margin: 0 auto;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

const IMG = styled('img')`
  display: block;
  margin: 30px auto;
  border-radius: 30px;

  @media (max-width: 500px) {
    border-radius: 0;
  }

  ${'' /* transform: perspective(1000px) rotateX(45deg);
  transition: transform 0.5s ease-out;

  &:hover {
    transform: rotateX(0)
  } */}
`;

export default IndexPage
