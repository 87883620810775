import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

function SectionCTA({ cta, color }) {
    return (
        <SPAN>
            <LINK to='/pricing' color={color}>
                <span>{cta}&nbsp;&nbsp;</span><i className='material-icons'>arrow_forward</i>
            </LINK>
        </SPAN>
    );
}
SectionCTA.defaultProps = {
    cta: `Try out the Insight Lifestyle for $9`,
};

const SPAN = styled('span')``;

const LINK = styled(Link)`
    color: ${prop => prop.color};
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    display: inline-flex;
    place-items: center;

    i {
        position: relative;
        font-size: 2rem;
        top: 2px;

        align-self: center;
    }

    &:hover {
        opacity: 0.6;
    }

    @media (max-width: 500px) {
        div {
            max-width: 200px;
        }
    }
`;

export default SectionCTA;