import React, { useState, useEffect } from 'react';
// inspired by https://upmostly.com/tutorials/setinterval-in-react-components-using-hooks

const rotationDelayInMS = 1500;

const benefitList = [
    'grow your profit and income',
    'create new habits',
    'improve your relationships',
    'develop confidence',
    'end procrastination',
    'get healthy',
    'change your life'
];

const BenefitRotator = () => {
    const [index, setIndex] = useState(0);

    useEffect( 
        function makeInterval() {
            const myInterval = setInterval(  // need to call this something so I can clear it later
                function indexSetter() {
                    setIndex(
                        function indexResetter(index) {
                            return index < benefitList.length - 1 ? index + 1 : 0;
                        });
                }, 
                rotationDelayInMS
            );
            return function intervalClearer() {
                clearInterval(myInterval);
            }
        }, 
        []
    );

    return (<>{benefitList[index]}</>);
};

export default BenefitRotator;